/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

.loading-img {
    display: block;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
}
